
import { Component, Vue } from 'nuxt-property-decorator'
import type { Offer } from '@alao-frontend/core'
import { createAmpOfferInstance, createOfferInstance, isBundleOffer } from '@alao-frontend/core'
import AppTransition from '~/components/AppTransition/AppTransition.vue'
import ExpandableCard from '~/components/ExpandableCard/ExpandableCard.vue'
import ExpandableCardHeader from '~/components/ExpandableCard/ExpandableCardHeader.vue'
import ExpandableCardBody from '~/components/ExpandableCard/ExpandableCardBody.vue'
import IconLightning from '~/assets/icons/icon-lightning.svg?inline'
import { $api } from '~/plugins/axios'
import { OnMounted } from '~/core/vue.types'
import OfferDeal from '~/components/DealOfTheDay/OfferDeal/OfferDeal.vue'
import { FeatureFlags } from '~/core/config/feature-flags'

enum TrackEventAction {
  CTA_CLICK = 'cta_click',
  SHOWN = 'shown',
  FOLD_CLICK = 'fold_click',
  UNFOLD_CLICK = 'unfold_click',
}

const trackEventCategory = 'daily_deal_banner'
const displayTimeoutMs = 5000 // 5 seconds before the banner is shown

@Component({
  components: {
    OfferDeal,
    ExpandableCardBody,
    AppTransition,
    ExpandableCardHeader,
    ExpandableCard,
    IconLightning,
  },
})
export default class DealOfTheDay extends Vue implements OnMounted {
  isVisible = false

  private expanded = true

  offer: Offer | null = null
  imageUrl: string | null = null

  get isExpanded () {
    return this.expanded
  }

  set isExpanded (value: boolean) {
    this.expanded = value
    this.sendTrackEvent(
      value ? TrackEventAction.UNFOLD_CLICK : TrackEventAction.FOLD_CLICK,
    )
  }

  async fetchOffer () {
    try {
      if (this.$featureFlags.isEnabled(FeatureFlags.AMP_COMPARATOR)) {
        const { items } = await $api.PublicHandbookService.searchHandbooks({
          handbook_slug: 'daily_deal',
          page: 1,
          per_page: 1,
        })

        if (items && items.length) {
          this.offer = createAmpOfferInstance(items[0].offer)
          this.imageUrl = items[0].image.original
          this.onOfferReady()
        }
      } else {
        const {
          imageUrl, offer,
        } = await $api.OfferService.getDailyDealOffer()

        this.offer = createOfferInstance(offer)
        this.imageUrl = imageUrl
        this.onOfferReady()
      }
    } catch (e) {}
  }

  onOfferReady () {
    setTimeout(() => {
      this.isVisible = true
      this.sendTrackEvent(TrackEventAction.SHOWN)
    }, displayTimeoutMs)
  }

  onCTAClick () {
    this.sendTrackEvent(TrackEventAction.CTA_CLICK)
  }

  sendTrackEvent (action: TrackEventAction) {
    if (!this.offer) {
      return
    }

    const category = this.$analytics.utils.getOfferCategoryName(this.offer.offerType)
    const providerName = isBundleOffer(this.offer)
      ? this.offer.providers.map(p => p.name).concat(' & ')
      : this.offer.provider.name

    this.$analytics.gtm.trackCustomEvent('GTMevent-v2', {
      eventName: trackEventCategory.concat('_', action),
      data: {
        event_label: `${category}|${this.offer.name}|${this.offer.id}`,
        event_action: action,
        event_category: trackEventCategory,
        plan_category: category,
        provider: providerName,
        plan_id: this.offer.id.toString(),
        plan_name: this.offer.name,
        language_code: this.$i18n.locale.toUpperCase(),
      },
      _clear: true,
    })
  }

  mounted () {
    this.fetchOffer()
  }
}
