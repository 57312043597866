
import { Component, Vue } from 'nuxt-property-decorator'

import AppLayout from '~/components/AppLayout.vue'
import AppHeader from '~/components/AppHeader'
import AppOfflineBar from '~/components/AppOfflineBar.vue'
import AppFooter from '~/components/AppFooter.vue'
import AppHubspotChat from '~/components/AppHubspotChat.vue'
import PageDynamicBanner from '~/components/banners/PageDynamicBanner'
import DealOfTheDay from '~/components/DealOfTheDay/DealOfTheDay.vue'
import AppLayoutOverlay from '~/components/AppLayoutOverlay/AppLayoutOverlay.vue'
import CookieBanner from '~/components/CookieBanner.vue'

@Component({
  components: {
    CookieBanner,
    AppLayoutOverlay,
    DealOfTheDay,
    AppLayout,
    AppHeader,
    PageDynamicBanner,
    AppFooter,
    AppHubspotChat,
    AppOfflineBar,
  },
})
export default class DefaultLayout extends Vue {
  get isHeaderWithShadow () {
    return this.localePath('index') !== this.$route.path
  }

  get isShowPageBanner () {
    return this.localePath('index') !== this.$route.path ||
      (this.localePath('index') === this.$route.path && !this.$mqUtils.isMobile)
  }

  get keepAliveKey () {
    return this.$route.fullPath
  }

  created () {
    if (process.client) {
      this.$analytics.emarsys.init()
    }
  }
}
