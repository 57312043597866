import type { NuxtRuntimeConfig } from '@nuxt/types/config/runtime'
import type { NuxtAxiosInstance } from '@nuxtjs/axios'
import {
  AddressService as PublicAddressService,
  BaseInfoService as PublicBaseInfoService,
  HandbookService as PublicHandbookService,
  OfferService as PublicOfferService,
  SalesService as PublicSalesService,
  PromoCodeService as PublicPromoCodeService,
  WhiteLabelService as PublicWhiteLabelService,
  CashbackService as PublicCashbackService,
} from '@alao-frontend/erp-services'
import { AddressService } from '~/services/address.service'
import { CommonService } from '~/services/common.service'
import { FilesService } from '~/services/files.service'
import { GeocodeService } from '~/services/geocode.service'
import { OfferService } from '~/services/offer.service'
import { OrderService } from '~/services/order.service'
import { BlogService } from '~/services/blog.service'
import { RatingService } from '~/services/rating.service'
import { TrackerService } from '~/services/tracker.service'
import { StripeService } from '~/services/stripe.service'
import { UserService } from '~/services/user.service'
import { ModalWindowSettingsService } from '~/services/modal-window-settings.service'
import { NotifierService } from '~/services/notifier.service'
import { PartnerService } from '~/services/partner.service'
import { EmarsysService } from '~/services/emarsys.service'
import { PromoService } from '~/services/promo/promo.service'
import { AbService } from '~/services/ab.service'

// TODO:
//  1. Come up with the idea how to create services in a more convenient way
export const createServices = (
  httpClient: NuxtAxiosInstance,
  runtimeConfig: NuxtRuntimeConfig,
) => {
  return {
    AddressService: new AddressService(httpClient, runtimeConfig),
    CommonService: new CommonService(httpClient, runtimeConfig),
    FilesService: new FilesService(httpClient, runtimeConfig),
    GeocodeService: new GeocodeService(httpClient, runtimeConfig),
    OfferService: new OfferService(httpClient, runtimeConfig),
    OrderService: new OrderService(httpClient, runtimeConfig),
    BlogService: new BlogService(httpClient, runtimeConfig),
    RatingService: new RatingService(httpClient, runtimeConfig),
    TrackerService: new TrackerService(httpClient, runtimeConfig),
    StripeService: new StripeService(httpClient, runtimeConfig),
    UserService: new UserService(httpClient, runtimeConfig),
    ModalWindowSettingsService: new ModalWindowSettingsService(httpClient, runtimeConfig),
    NotifierService: new NotifierService(httpClient, runtimeConfig),
    PartnerService: new PartnerService(httpClient, runtimeConfig),
    EmarsysService: new EmarsysService(httpClient, runtimeConfig),
    PromoService: new PromoService(httpClient, runtimeConfig),
    AbService: new AbService(httpClient, runtimeConfig),
    PublicSalesService: new PublicSalesService(httpClient, { ...runtimeConfig, BASE_API_URL: runtimeConfig.BASE_API_URL }),
    PublicAddressService: new PublicAddressService(httpClient, { ...runtimeConfig, BASE_API_URL: runtimeConfig.BASE_API_URL }),
    PublicBaseInfoService: new PublicBaseInfoService(httpClient, { ...runtimeConfig, BASE_API_URL: runtimeConfig.BASE_API_URL }),
    PublicOfferService: new PublicOfferService(httpClient, { ...runtimeConfig, BASE_API_URL: runtimeConfig.BASE_API_URL }),
    PublicHandbookService: new PublicHandbookService(httpClient, { ...runtimeConfig, BASE_API_URL: runtimeConfig.BASE_API_URL }),
    PublicPromoCodeService: new PublicPromoCodeService(httpClient, { ...runtimeConfig, BASE_API_URL: runtimeConfig.BASE_API_URL }),
    PublicWhiteLabelService: new PublicWhiteLabelService(httpClient, { ...runtimeConfig, BASE_API_URL: runtimeConfig.BASE_API_URL }),
    PublicCashbackService: new PublicCashbackService(httpClient, { ...runtimeConfig, BASE_API_URL: runtimeConfig.BASE_API_URL }),
  } as const
}
