const middleware = {}

middleware['nuxti18nExtended'] = require('../node_modules/.pnpm/@alao-frontend+i18n-extend@0.0.1_@alao-frontend+utils@2.2.0_@nuxt+types@2.17.3_@nuxtjs+i18n@7.3.1_vue-router@3.6.5/node_modules/@alao-frontend/i18n-extend/lib/templates/middleware.js')
middleware['nuxti18nExtended'] = middleware['nuxti18nExtended'].default || middleware['nuxti18nExtended']

middleware['mailRedirects'] = require('../middleware/mailRedirects.ts')
middleware['mailRedirects'] = middleware['mailRedirects'].default || middleware['mailRedirects']

middleware['maintenancePage'] = require('../middleware/maintenancePage.ts')
middleware['maintenancePage'] = middleware['maintenancePage'].default || middleware['maintenancePage']

middleware['partnerLayout'] = require('../middleware/partnerLayout.ts')
middleware['partnerLayout'] = middleware['partnerLayout'].default || middleware['partnerLayout']

middleware['siteLevelAdsCookies'] = require('../middleware/siteLevelAdsCookies.ts')
middleware['siteLevelAdsCookies'] = middleware['siteLevelAdsCookies'].default || middleware['siteLevelAdsCookies']

middleware['siteLevelPromoCode'] = require('../middleware/siteLevelPromoCode.ts')
middleware['siteLevelPromoCode'] = middleware['siteLevelPromoCode'].default || middleware['siteLevelPromoCode']

middleware['siteLevelReferralLink'] = require('../middleware/siteLevelReferralLink.ts')
middleware['siteLevelReferralLink'] = middleware['siteLevelReferralLink'].default || middleware['siteLevelReferralLink']

middleware['trailingSlashRedirect'] = require('../middleware/trailingSlashRedirect.ts')
middleware['trailingSlashRedirect'] = middleware['trailingSlashRedirect'].default || middleware['trailingSlashRedirect']

export default middleware
