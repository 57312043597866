
import { Component, Vue } from 'nuxt-property-decorator'

import AppLayout from '~/components/AppLayout.vue'
import AppHeader from '~/components/AppHeader'
import AppOfflineBar from '~/components/AppOfflineBar.vue'
import AppFooter from '~/components/AppFooter.vue'

@Component({
  components: {
    AppLayout,
    AppHeader,
    AppFooter,
    AppOfflineBar,
  },
})
export default class HeaderNoNavLayout extends Vue {
}
