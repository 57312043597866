import { Middleware } from '@nuxt/types'
import { createNuxtMiddleware } from '@alao-frontend/utils'
import { FeatureFlags } from '~/core/config/feature-flags'

const maintenancePage: Middleware = ctx => createNuxtMiddleware(ctx, () => {
  const isMaintenance = ctx.$featureFlags.isEnabled(FeatureFlags.MAINTENANCE_MODE_ENABLED)

  if (isMaintenance) {
    // Redirect to maintenance page if maintenance mode is enabled

    if (ctx.route.path !== ctx.localePath('maintenance')) {
      return ctx.redirect(ctx.localePath('maintenance'))
    }

    return
  }

  // Redirect to home page if user tries to access maintenance page
  if (ctx.route.path === ctx.localePath('maintenance')) {
    return ctx.redirect(ctx.localePath('index'))
  }
})

export default maintenancePage
